<template>
  <section v-if="data" class="o-review-list">
    <div class="container">
      <h2 class="o-review-list__title">{{ data.title }}</h2>
      <div class="o-review-list__reviews">
        <div v-for="col in makeCols(data.items, 3)" class="o-review-list__reviews-col">
          <Card
            v-for="(review, i) in col"
            :key="i"
            css-class="m-card-review"
            :characters="{
              all: review.bodyLength,
              visible: 300,
            }"
          >
            <template #contentHeader>
              <Tag tag="span" theme="light">{{ review.appliance }}</Tag>
              <div class="m-card-review__heading">
                {{ review.customerName }}
                <span>Заявка {{ review.bidId }}</span>
              </div>

              <Rating
                v-if="review.rating"
                v-model="review.rating"
                theme="light"
                readonly
                show-label
                :star-count="5"
                :rounding-step="0.5"
              >
                <template #active>
                  <i class="icon-main-starfill"></i>
                </template>

                <template #inactive>
                  <i class="icon-main-starfill"></i>
                </template>
              </Rating>
            </template>

            <template #content>
              <div v-html="review.body"></div>
            </template>

            <template #contentFooter="{ isCollapsed, visibleCharactersHandler }">
              <Button
                v-if="review.bodyLength > 300"
                css-class="button-icon-text"
                modifier="primary"
                icon-pos="right"
                aria-label="Icon"
                @click="visibleCharactersHandler"
              >
                <template #icon>
                  <i :class="`icon-main-${isCollapsed ? 'down' : 'up'}`"></i>
                </template>
                {{ isCollapsed ? 'Развернуть' : 'Свернуть' }}
              </Button>
            </template>
          </Card>
        </div>
      </div>
      <div v-if="data.linkHub" class="o-review-list__button">
        <Button modifier="tertiary" theme="light" tag="a" :href="data.linkHub"> Все отзывы </Button>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Card from '@ice-products-ui/vue-library/Card';
import Tag from '@ice-products-ui/vue-library/Tag';
import Rating from '@ice-products-ui/vue-library/Rating';
import Button from '@ice-products-ui/vue-library/Button';

// Composables
import useGetData from '~/composables/api/useGetData';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TOReviewList } from '~/components/O/Review/List/OReviewList.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Review/List/OReviewList.schema';

// Utils
import makeCols from '~/utils/arrays/makeCols';

defineOptions({
  name: 'OReviewList',
});

const props = withDefaults(defineProps<IComponentProps<TOReviewList>>(), {
  viewName: 'OReviewList',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TOReviewList>();

const data = ref<TOReviewList>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
